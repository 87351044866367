import { useIntl } from "gatsby-plugin-intl"
import React, { Fragment } from "react"
import { Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faInstagram,
  faFacebook,
  faTiktok,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"
import { faMapMarkerAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import LogoLight from "../images/ngebiz-logo-nav.png"
import Gplay from "../images/google-play-badge.png"
import Aplay from "../images/app-store.png"

const FooterSection = ({ siteTitle }) => {
  const intl = useIntl()
  const locale = intl.locale !== "id" ? `/${intl.locale}` : ""
  const lang = intl.locale === "en" ? "US" : "ID"

  return (
    <Fragment>
      <div className={"footer pl-5 pr-5 pt-0"}>
        <Row className={"d-flex justify-content-center"}>
          <Col md={4} className={"p-4"}>
            <a href="/">
              <img src={LogoLight} alt="logo-ngebiz" width={"60%"} />
            </a>
            <small>
              <br />
              <Row>
                <Col sm={1}>
                  <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>
                </Col>
                <Col>
                  Penen RT 02/RW 33 No. 14, Penen, Donoharjo, Kec. Ngaglik,
                  Kabupaten Sleman, Daerah Istimewa Yogyakarta 55581, Indonesia.
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={1}>
                  <FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon>
                </Col>
                <Col>+6282136470202</Col>
              </Row>
              <br />
              <Row>
                <Col sm={1}>
                  <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                </Col>
                <Col sm={11}>ngebis.info@gmail.com</Col>
              </Row>
            </small>
          </Col>
          <Col md={4} className={"pt-4 pl-4 ml-3"}>
            <Row>
              <Col md={6}>
                <Row className={"my-1"}>
                  <a href={`${locale}/about-us`} className={"btn-footer"}>
                    {intl.formatMessage({ id: "about-us" })}
                  </a>
                </Row>
                <Row className={"my-1"}>
                  <a href={`${locale}/`} className={"btn-footer"}>
                    {intl.formatMessage({ id: "careers" })}
                  </a>
                </Row>
                <Row className={"my-1"}>
                  <a href={`${locale}/articles`} className={"btn-footer"}>
                    {intl.formatMessage({ id: "blog" })}
                  </a>
                </Row>
              </Col>
              <Col md={6}>
                <Row className={"my-1"}>
                  <a
                    href={`${locale}/terms-condition`}
                    className={"btn-footer"}
                  >
                    {intl.formatMessage({ id: "terms-condition" })}
                  </a>
                </Row>
                <Row className={"my-1"}>
                  <a href={`${locale}/privacy-policy`} className={"btn-footer"}>
                    {intl.formatMessage({ id: "privacy-policy" })}
                  </a>
                </Row>
                <Row className={"my-1"}>
                  <a href={`${locale}/help`} className={"btn-footer"}>
                    {intl.formatMessage({ id: "help" })}
                  </a>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={3} className={"p-4"}>
            <Row className={"text-right"}>
              <p style={{ marginLeft: "1em" }}>Download</p>
            </Row>
            <Row>
              <Col>
                <a href="https://play.google.com/store/apps/details?id=com.ngebis.ngebis_customer">
                  <img src={Gplay} alt="gplay-ngebiz" />
                </a>
              </Col>
              <Col>
                <a href="https://apps.apple.com/id/app/ngebis-pesan-dan-lacak-bis/id1537058834">
                  <img src={Aplay} alt="gplay-ngebiz" />
                </a>
              </Col>
            </Row>
            <Row className={"text-right"}>
              <p style={{ marginLeft: "1em" }}>
                {intl.formatMessage({ id: "follow-us" })}
              </p>
            </Row>
            <Row>
              <Col>
                <a
                  href="https://www.facebook.com/ngebisaja"
                  style={{ color: "inherit" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
                </a>
              </Col>
              <Col>
                <a
                  href="https://www.instagram.com/ngebisaja"
                  style={{ color: "inherit" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
                </a>
              </Col>
              <Col>
                <a
                  href="https://www.twitter.com/ngebisaja"
                  style={{ color: "inherit" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
                </a>
              </Col>
              <Col>
                <a
                  href="https://www.tiktok.com/@ngebisaja"
                  style={{ color: "inherit" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTiktok}></FontAwesomeIcon>
                </a>
              </Col>
              <Col>
                <a
                  href="https://www.youtube.com/channel/UCqDj-2dx47sjMBJm6DaCoAg"
                  style={{ color: "inherit" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <footer className={"text-muted text-center mt-3"}>
              <hr className={"bg-white"} />
              <small>
                Ngebis © {new Date().getFullYear()}
                <p>{intl.formatMessage({ id: "copyright" })}</p>
              </small>
            </footer>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

FooterSection.propTypes = {}

FooterSection.defaultProps = {
  siteTitle: ``,
}

export default FooterSection
