import Logo from "../images/ngebiz-logo-nav.png"
import PropTypes from "prop-types"
import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import LanguageSelector from "./selector"

const Header = ({ siteTitle }) => {
  const intl = useIntl()
  const locale = intl.locale !== "id" ? `/${intl.locale}` : ""
  const lang = intl.locale === "en" ? "US" : "ID"

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light">
        <a className="navbar-brand" href={`${locale}/`}>
          <img src={Logo} alt="logo-ngebiz" width={"35%"} />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
          style={{ width: "37em" }}
        >
          <ul className="navbar-nav ml-auto">
            <li className={"nav-item"}>
              <a className="nav-link" href={`${locale}/`}>
                {intl.formatMessage({ id: "home" })}
              </a>
            </li>
            <li className={"nav-item"}>
              <a className="nav-link" href={`${locale}/articles`}>
                {intl.formatMessage({ id: "blog" })}
              </a>
            </li>
            <li className={"nav-item"}>
              <a className="nav-link" href={`${locale}/about-us`}>
                {intl.formatMessage({ id: "about-us" })}
              </a>
            </li>
            <li className={"nav-item"}>
              <a className="nav-link" href={`${locale}/help`}>
                {intl.formatMessage({ id: "help" })}
              </a>
            </li>
          </ul>
          <LanguageSelector locale={`${lang}`}></LanguageSelector>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
