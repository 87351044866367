import React from "react"
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import 'react-flags-select/scss/react-flags-select.scss';
import { navigate } from "gatsby"

const LanguageSelector = ({ locale }) => {

    return (
        <ReactFlagsSelect
            countries={["US", "ID"]}
            customLabels={{"US": "EN - English","ID": "ID - Indonesia"}}
            placeholder="Select Language"
            className={"select-lang"}
            defaultCountry={locale}
            onSelect={(countryCode) => {
                if (countryCode === "ID") {
                    navigate("/id")
                } else {
                    navigate("/en")
                }
            }}
        />
    )
}

export default LanguageSelector
