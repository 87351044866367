/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "../../node_modules/jquery/dist/jquery.min"
import { useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.scss"
import { Col, Container, Row } from "react-bootstrap"
import FooterSection from "./footer"

const Layout = ({ children }) => {
  const intl = useIntl()
  const locale = intl.locale !== "id" ? `/${intl.locale}` : ""
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <Container>
        <Row className={"justify-content-md-center"}>
          <Col>
            <Header siteTitle={data.site.siteMetadata.title} />
            <div
              style={{
                margin: `0 auto`,
                maxWidth: 1024,
                // padding: `0 1.0875rem 1.45rem`,
              }}
            >
              <main>{children}</main>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={"hr-footer"}></div>
      <FooterSection />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
